// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.0-10303(1c291ffaee)-C19/08/2024-15:26:54-B19/08/2024-15:31:20' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.0-10303(1c291ffaee)-C19/08/2024-15:26:54-B19/08/2024-15:31:20",
  branch: "production",
  latestTag: "6.0",
  revCount: "10303",
  shortHash: "1c291ffaee",
  longHash: "1c291ffaeea813cbe4d8150d2f9fb28a2deac2f0",
  dateCommit: "19/08/2024-15:26:54",
  dateBuild: "19/08/2024-15:31:20",
  buildType: "Ansible",
  } ;
